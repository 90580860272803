// Here you can add other styles
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@500&family=Quicksand&display=swap");

.classbglogin {
	background: transparent url(../img/bg_gradient_top.png) top left repeat-x;
}
.classbgtransparent {
	background-color: transparent !important;
}
.classbgwhite {
	background-color: white;
}
.classheadermain {
	background: #f0f0f0 url(../img/bg_header_top.png) top left repeat-x;
}
.classtitle,.classtitlelogin {
	color: #4c4d4d;
	font-family: "Encode Sans", sans-serif;
	font-size: 18px;
	text-decoration: none !important;
	font-weight: bolder;
}
.classtitlesidebar {
	font-size: 15px;
	color: white;
	font-weight: normal;
}
.classtitlesub {
	font-size: 140%;
	color: gray;
}
.classposmidcenter {
	top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1060;
}
.classfontsmaller {
	font-size: smaller;
}
.classfontitalic {
	font-style: italic;
}
.classfontlarger {
	font-size: larger;
}
.classcolorgreen {
	color:green;
}
.classpetunjuk {
	color: #3399ff;
	font-style:italic;
	font-size:85%;
	padding-left:15px;
}
.classborderr1 {
	border:15px solid red;
	border-color: rgba(silver,0.3);
	border-radius: 15px;
}
.classToTop {
	cursor:pointer;
	position:fixed;
	min-width: 30px;
	z-index:2065;
	bottom: 35px;
	right:20px;
	opacity:1;
	transition: width 0.1s, height 0.1s, opacity 0.1s 0.1s;
}
.classbordertmp {
	border: 1px solid red !important;
}
.classbordertop {
	border-top: 1px solid #DCDCDC;
}
.classborderbottom{
	border-bottom: 1px solid #DCDCDC;
}
.classbordertopdobel {
	border-top: 4px double silver !important;
}
.classtabelfakethead {
	font-size:larger;
	font-weight: bolder;
	background-color: silver;
	color:white;
}
.classtabeltfoot {
	background-color: #EDFCF1;
	border-top: 1px solid #DCDCDC;
}
.classtabeltfootlaporan {
	background-color: #DCDCDC;
	border-top: 1px solid silver;
	font-size: larger;
	color: blue;
}
.classrowselect {
	background-color: rgba(#FCF3CF, 0.5) !important;
}
.classautocomplete {
	position: absolute;
	width: 95%;
	z-index: 1000;
	background-color: white;
}
.classautocompletelist {
	cursor: pointer;
	border-bottom: 1px dashed #DCDCDC;/**/
}

.classradiobutton {
	border:1px solid #f0f0f0;
	border-radius: 5px !important;
	padding: 7px !important;
	padding-left:35px !important;
	margin-bottom: 5px;
	background-color: #F6F7F8;
}
.classradiobutton:hover {
	background-color: #f0f0f0;
}
.classradiobutton label {
	display: block;
	cursor: pointer;
	/*border: 1px solid red;/**/
}
.classradiobutton input[radio]:checked {
	background-color: blue !important;
}
.classikontabel {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    margin-right: 3px;
}
.classikon {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin-right: 3px;
}
.classikonavatar {
    background: transparent url(../img/icon_umum.png) center center no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    background-color: #DCDCDC !important;
    border: 1px dotted white;
}
.classikonedit{
    background: transparent url(../img/icon_edit.png) center center no-repeat;
    background-size: cover;
}
.classikonhapus{
    background: transparent url(../img/icon_hapus.png) center center no-repeat;
    background-size: cover;
}
.classikonsetrole{
    background: transparent url(../img/menus_setrole.png) center center no-repeat;
    background-size: cover;
}
.classikononline{
    background: transparent url(../img/icon_online.png) center center no-repeat;
    background-size: cover;
}
.classikonoffline{
    background: transparent url(../img/icon_offline.png) center center no-repeat;
    background-size: cover;
}
.classikontambah {
    background: transparent url(../img/icon_tambah.png) center center no-repeat;
    background-size: cover;
}
.classikontambahitem {
    background: transparent url(../img/icon_tambahitem.png) center center no-repeat;
    background-size: cover;
}
.classikonreset {
    background: transparent url(../img/icon_reset.png) center center no-repeat;
    background-size: cover;
}
.classikoninfo {
	background: transparent url(../img/icon_info.png) center center no-repeat;
	background-size: cover;
}
.classikonchecked {
	background: transparent url(../img/icon_checked.png) center center no-repeat;
	background-size: cover;
}
.classikonloading {
	background: transparent url(../img/icon_loading.gif) center center no-repeat;
	background-size: cover;
    width: 18px;
    height: 18px;
}
.classikonbayar {
	background: transparent url(../img/icon_bayar.png) center center no-repeat;
	background-size: cover;
}
.classikonhistoribayar {
	background: transparent url(../img/icon_historibayar.png) center center no-repeat;
	background-size: cover;
}
.classikontambahstok{
	background: transparent url(../img/icon_stoktambah.png) center center no-repeat;
	background-size: cover;
}
.classikonkurangstok{
	background: transparent url(../img/icon_stokkurang.png) center center no-repeat;
	background-size: cover;
}
.classikoncetak {
	background: transparent url(../img/icon_cetak.png) center center no-repeat;
	background-size: cover;
}
.classikonexcel {
	background: transparent url(../img/icon_excel.png) center center no-repeat;
	background-size: cover;
}

.classikonfreerole {
	background: transparent url(../img/icon_freerole.png) center center no-repeat;
	background-size: cover;
}
.classikonmanajer {
	background: transparent url(../img/icon_manajer.png) center center no-repeat;
	background-size: cover;
}
.classikonentri {
	background: transparent url(../img/icon_entri.png) center center no-repeat;
	background-size: cover;
}

.classfakesubheader {
	margin:-15px -45px 10px -45px;/**/
	width:auto !important;
	padding: 15px;
	background-color: white;
}

.classulreguler{
	border: none;
	margin:0;

	/*border:1px solid red;/**/
}
.classlireguler{
	padding: 3px;
	list-style: square inside !important;
}
